import { roundDecimal } from '@/utils/number-utils'
import { Decimal } from 'decimal.js-light'
import { discountTypes } from '../dominio'

const payBoxUtils = {
  calculateItemPrice({
    price,
    originalPrice,
    discountType,
    unitDiscount,
    quantity,
    priceTableDiscount,
    promotionId,
    tieredPrices,
  }) {
    const { PERCENTAGE, VALUE } = discountTypes.computed.discountTypesEnum()
    const tiererPrice = this.getPrice(quantity, tieredPrices)
    const localPrice = tiererPrice?.price || originalPrice || price

    let netValue = localPrice * quantity
    let totalDiscount = unitDiscount || 0

    // O arredondamento dos descontos para 2 casas decimais deve ser feito sempre antes de multiplicar pela quantidade.
    let unitDiscountValue = unitDiscount
    if (unitDiscount) {
      if (discountType === PERCENTAGE) {
        unitDiscountValue = new Decimal(localPrice)
          .times(unitDiscount || 0)
          .toDecimalPlaces(2)
          .toNumber()
        totalDiscount = unitDiscountValue * quantity
      } else if (discountType === VALUE) {
        totalDiscount = unitDiscount * quantity
      }
    }

    const localPriceTableDiscount = priceTableDiscount || 0
    const isToApplyPriceTableDiscount = !promotionId && priceTableDiscount > 0
    let priceTableDiscountValue = 0
    if (isToApplyPriceTableDiscount) {
      priceTableDiscountValue = new Decimal(localPrice)
        .times(localPriceTableDiscount || 0)
        .toDecimalPlaces(2)
        .toNumber()
      totalDiscount += priceTableDiscountValue * quantity
    }

    totalDiscount = roundDecimal(totalDiscount, 2)
    const totalUnitDiscount = roundDecimal(unitDiscountValue + priceTableDiscountValue, 2)

    netValue -= totalDiscount
    return {
      netValue: roundDecimal(netValue),
      netUnitDiscount: totalUnitDiscount,
      priceInfo: {
        price: localPrice,
        originalPrice: originalPrice ?? price,
        priceWithDiscount: roundDecimal(localPrice - totalUnitDiscount),
        discountTotal: totalDiscount,
        priceTableDiscountTotal: roundDecimal(priceTableDiscountValue * quantity),
        grossValue: roundDecimal(localPrice * quantity),
        skuTieredPriceId: tiererPrice?.id,
      },
    }
  },

  getDiscountValue({ discountType, discountValue, totalValue, priceTableDiscount, promotionId }) {
    const { PERCENTAGE, VALUE } = discountTypes.computed.discountTypesEnum()
    let result = 0
    if (discountType === VALUE) result = discountValue
    if (discountType === PERCENTAGE)
      result = new Decimal(totalValue)
        .times(discountValue || 0)
        .toDecimalPlaces(2)
        .toNumber()

    const isToApplyPriceTableDiscount = !promotionId && priceTableDiscount > 0
    if (isToApplyPriceTableDiscount)
      result += new Decimal(totalValue)
        .times(priceTableDiscount || 0)
        .toDecimalPlaces(2)
        .toNumber()

    return roundDecimal(result, 2)
  },

  /**
   *
   * @param {Object} item
   * @param {Number} item.unitValue
   * @param {Number} item.unitDiscount
   * @param {Number} item.quantity
   * @param {String} item.discountType
   * @returns
   */
  formatSimpleSaleItem(item) {
    const { priceInfo } = this.calculateItemPrice({
      price: item.unitValue,
      unitDiscount: item.unitDiscount,
      quantity: item.quantity,
      discountType: item.discountType,
      promotionId: item.promotionId,
      priceTableDiscount: item.discountPriceTable,
    })

    return {
      ...item,
      price: priceInfo.priceWithDiscount,
      discountTotalValue: priceInfo.discountTotal,
      name: item.name || item.sku?.name || '',
    }
  },

  getPrice(quantity, tieredPrices) {
    return tieredPrices?.find(
      price =>
        quantity >= price.quantityFrom &&
        (price.quantityTo === null || quantity <= price.quantityTo)
    )
  },
}

export default payBoxUtils
