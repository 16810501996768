/* eslint-disable eqeqeq */
import Vue from 'vue'
import axios from '@axios'
import lodash from 'lodash'
import productDomains from '@/mixins/dominio/product-domains'

const getInitialState = () => ({
  isReadOnly: false,
  product: {
    description: '',
    productType: undefined,
    brandId: null,
    groupId: null,
    subgroupId: null,
    productLineId: null,
    productUnitId: null,
    departmentId: null,
    lendingProductAssociatedId: null,
    contractualFine: null,
    classification: undefined,
    sku: '',
    milliliters: undefined,
    tags: [],
    active: true,
    barcode: '',
    image: null,
    activeOnline: false,
    customerRequired: false,
    featuredProduct: false,
    hasVariations: false,
    variations: [],
    integrateWithBeerCooler: false,
    activeB2B: false,
    unitsPerPack: null,
    tax: {
      productTaxType: undefined,
      productOrigin: undefined,
      ncmCode: null,
      cestId: null,
    },
    skus: [
      {
        variations: [],
        ean: undefined,
        active: true,
        hasCustomPrices: false,
        prices: [],
        // basePrice: {
        //   price: 0,
        //   costPrice: 0,
        //   averagePrice: 0,
        //   promotionalPrice: 0,
        //   wholesalePrice: 0,
        // },
      },
    ],
  },
  variationsList: [],
  // selectedVariants: [],
  priceTables: [],
  tieredPrices: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    productDescription(state, getters) {
      const parts = [
        getters.selectedGroup?.name,
        getters.selectedSubgroup?.name,
        getters.selectedBrand?.name,
        getters.selectedLine?.name,
      ]
      return parts.filter(value => !!value).join(' ') || '-'
    },
    skuPrices: state => skuIndex => {
      const sku = state.product.skus[skuIndex]
      const { priceTables } = state
      priceTables.forEach(pt => {
        const price = sku.prices.find(p => p.priceTable?.id === pt.id)
        if (!price) {
          sku.prices.push({
            priceTable: pt,
            store: { id: null },
            price: 0,
          })
        }
      })
      return sku.prices
    },
    skuPriceTables: state => skuIndex => {
      const sku = state.product.skus[skuIndex]
      const { priceTables } = state
      // debugger
      const pricesByStore = lodash.groupBy(
        sku.prices,
        p => `${p.store.id}.${p.store.tradingName}`
      )
      const skuPriceTables = []
      Object.keys(pricesByStore).forEach(store => {
        let [storeId, storeName] = store.split('.')
        if (storeName === 'null') {
          storeId = -1
          storeName = 'Geral'
        }
        const storePrices = {
          storeId,
          storeName,
        }
        priceTables.forEach(pt => {
          const price = pricesByStore[store].find(p => p.priceTable.id === pt.id)
          storePrices[`priceTable_${pt.id}`] = price?.price || 0
        })
        skuPriceTables.push(storePrices)
      })
      return lodash.orderBy(skuPriceTables, p => p.storeId)
    },
    selectedVariants(state) {
      return state.product.variations
    },
    selectedGroup(state, getters, rootState) {
      return rootState.pages.catalog.products.productCombos['product-groups'].find(
        // eslint-disable-next-line eqeqeq
        item => item.id == state.product.groupId
      )
    },
    selectedSubgroup(state, getters, rootState) {
      return rootState.pages.catalog.products.productCombos['product-subgroups'].find(
        // eslint-disable-next-line eqeqeq
        item => item.id == state.product.subgroupId
      )
    },
    selectedBrand(state, getters, rootState) {
      return rootState.pages.catalog.products.productCombos?.brands?.find(
        // eslint-disable-next-line eqeqeq
        item => item.id == state.product.brandId
      )
    },
    selectedLine(state, getters, rootState) {
      return rootState.pages.catalog.products.productCombos?.lines?.find(
        // eslint-disable-next-line eqeqeq
        item => item.id == state.product.productLineId
      )
    },
    variationByName: state => variationName =>
      state.variationsList.find(item => item.name === variationName),
    priceTableName: state => priceTableId =>
      // eslint-disable-next-line eqeqeq
      state.priceTables.find(item => item.id == priceTableId)?.name,
    isSaleClassification(state) {
      return (
        state.product.classification === productDomains.computed.productClassificationsEnum().SALE
      )
    },
    priceTableOptions(state) {
      return state
        .priceTables?.filter(priceTable => priceTable.active === true)
        .map(priceTable => ({
          label: priceTable.name,
          value: priceTable.id,
        }))
    },
    tieredPricesFormatted(state) {
      const pricesGrouped = lodash.groupBy(
        state.tieredPrices.filter(price => !price.isDeleted),
        tieredPrice => `${tieredPrice.storeId}|${tieredPrice.priceTableId}`
      )
      const tieredPrices = []
      Object.keys(pricesGrouped).forEach(key => {
        const [storeId, priceTableId] = key.split('|')
        tieredPrices.push({
          storeId: storeId === 'null' ? null : storeId,
          priceTableId: parseInt(priceTableId, 10),
          prices: pricesGrouped[key],
        })
      })
      return tieredPrices
    },
  },

  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products
    },

    SET_PRICE_TABLES(state, priceTables) {
      state.priceTables = priceTables
    },

    SET_VARIANT_COMBO(state, variations) {
      state.variationsList = variations
    },

    SET_SELECTED_VARIANTS(state, value) {
      state.selectedVariants = value
    },

    ADD_SKU(state, sku) {
      if (typeof sku === 'object') state.product.skus.push(sku)
    },

    REMOVE_DEFAULT_VARIATION(state) {
      state.product.skus.splice(0, 1)
    },

    UPDATE_SKU(state, { sku, index }) {
      if (typeof sku === 'object' && index > -1) {
        state.product.skus[index] = sku
      }
    },

    REMOVE_SKU(state, index) {
      if (index !== undefined || index > -1) state.product.skus.splice(index, 1)
    },

    SET_PRODUCT(state, product) {
      state.product = product
      const [sku] = product.skus
      state.tieredPrices = sku?.tieredPrices
    },

    SET_PRODUCT_IDS(state, product) {
      state.product.id = product.id
      state.product.skus.forEach((sku, index) => {
        state.product.skus[index].id = product.skus[index].id
      })
    },

    CLEAN_PRODUCT(state) {
      const { product } = getInitialState()
      state.product = product
    },

    CLEAN_STATE(state) {
      const { product, sidebarState, selectedVariants, variationsList } = getInitialState()
      state.product = product
      state.sidebarState = sidebarState
      state.selectedVariants = selectedVariants
      state.variationsList = variationsList
    },
    SET_READ_ONLY(state, value) {
      state.isReadOnly = value
    },
    SET_TIERED_PRICES(state, tieredPrices) {
      const [firstPrice] = tieredPrices
      if(!firstPrice) throw new Error('Nenhuma configuração de preço escalonado cadastrado')

      const currentTieredPrices = state.tieredPrices.filter(
        price =>
          price.priceTableId == firstPrice.priceTableId && price.storeId == firstPrice.storeId
      )
      currentTieredPrices.forEach(price => {
        const priceIndex = state.tieredPrices.findIndex(
          p => p.priceTableId == price.priceTableId && p.storeId == price.storeId
        )
        if (priceIndex >= 0) {
          state.tieredPrices.splice(priceIndex, 1)
        }
      })
      state.tieredPrices.push(...tieredPrices)
    },
    DELETE_TIERED_PRICES(state, { priceTableId, storeId }) {
      state.tieredPrices
        // eslint-disable-next-line eqeqeq
        .filter(price => price.storeId == storeId && price.priceTableId == priceTableId)
        // eslint-disable-next-line no-param-reassign
        .forEach(price => {
          Vue.set(price, 'isDeleted', true)
        })
    },
  },

  actions: {
    setReadOnly({ commit }, value) {
      commit('SET_READ_ONLY', value)
    },
    async fetchComboVariants({ commit }) {
      const { results: variations } = (
        await axios.get(`/api/variations`, {
          params: {
            pageSize: 0,
          },
        })
      ).data

      commit(
        'SET_VARIANT_COMBO',
        variations.map(variation => ({
          ...variation,
          options: variation.values.map(value => ({
            value: value.id,
            label: value.value,
          })),
        }))
      )
    },

    getSkuPrices({ state }, skuIndex) {
      // if (skuIndex < 0) return []

      const sku = skuIndex >= 0 ? state.product.skus[skuIndex] : null
      const skuPrices = [...(sku?.prices || [])]
      const { priceTables } = state
      priceTables.forEach(pt => {
        const price = sku?.prices.find(p => p.priceTable?.id === pt.id)
        if (!price) {
          skuPrices.push({
            priceTable: pt,
            store: { id: null },
            price: 0,
          })
        }
      })
      return skuPrices.filter(p => !p.store?.id)
    },

    async fetchPriceTables({ commit }) {
      const { results: priceTables } = (
        await axios.get(`/api/price-tables`, {
          params: {
            pageSize: 0,
          },
        })
      ).data

      commit('SET_PRICE_TABLES', priceTables)
    },

    async fetchProduct({ commit, dispatch }, productId) {
      const { data: product } = await axios.get(`/api/products/${productId}`)
      // debugger
      product.groupId = product.group?.id.toString() || null
      await dispatch('pages/catalog/products/setComboSubgroups', product.groupId, { root: true })
      product.subgroupId = product.subgroup?.id.toString() || null
      product.brandId = product.brand?.id.toString() || null
      product.departmentId = product.department?.id.toString() || null
      product.productLineId = product.productLine?.id.toString() || null
      product.productUnitId = product.productUnit?.id.toString() || null
      product.lendingProductAssociatedId = product.lendingProductAssociated?.id?.toString() || null
      product.tax.cestId = product.tax?.cestId?.toString() || null
      product.variations = product.variations?.map(variation => variation.variationId) || []
      if (product.tax) {
        product.tax.productOrigin = product.tax.productOrigin?.toString() || null
        product.tax.productTaxType = product.tax.productTaxType?.toString() || null
      }
      product.tags = product.tags?.split(',')
      commit('SET_PRODUCT', product)
    },

    async saveProduct({ commit }, productPayload) {
      const result = (
        await axios({
          url: '/api/products',
          method: productPayload.id ? 'PUT' : 'POST',
          data: productPayload,
        })
      ).data
      commit('SET_PRODUCT_IDS', result)
    },

    setProduct({ commit }, product) {
      commit('SET_PRODUCTS', product)
    },

    setSidebarState({ commit }, sidebarState) {
      commit('SET_SIDEBAR_STATE', sidebarState)
    },

    setSelectedVariants({ commit }, value) {
      commit('SET_SELECTED_VARIANTS', value)
    },

    addSku({ commit, state }, { variations, prices }) {
      const [firstSku] = state.product.skus
      if (firstSku && !firstSku.variations?.length) {
        commit('REMOVE_DEFAULT_VARIATION')
      }
      const variationsToAdd = []
      const [newSku] = getInitialState().product.skus
      Object.keys(variations)
        .filter(key => key.startsWith('variation_'))
        .forEach(variationKey => {
          const variationId = parseInt(variationKey.split('_')[1], 10)
          const variation = state.variationsList.find(item => item.id === variationId)
          variationsToAdd.push({
            variationId: variation.id,
            variationName: variation.name,
            variationValueId:
              variation.valueType === 'Dropdown' ? variations[variationKey] : undefined,
            variationValue:
              variation.valueType === 'Dropdown'
                ? // eslint-disable-next-line eqeqeq
                  variation.values.find(val => val.id == variations[variationKey])
                : undefined,
            variationOpenValue:
              variation.valueType !== 'Dropdown' ? variations[variationKey] : undefined,
          })
        })
      newSku.variations.push(...variationsToAdd)
      newSku.prices = prices
      commit('ADD_SKU', newSku)
    },

    updateSku({ state, commit }, { variations, prices, index }) {
      const variationsToUpdate = []
      const sku = state.product.skus[index]
      Object.keys(variations)
        .filter(key => key.startsWith('variation_'))
        .forEach(variationKey => {
          const variationId = parseInt(variationKey.split('_')[1], 10)
          const variation = state.variationsList.find(item => item.id === variationId)
          variationsToUpdate.push({
            variationId: variation.id,
            variationName: variation.name,
            variationValueId:
              variation.valueType === 'Dropdown' ? variations[variationKey] : undefined,
            variationValue:
              variation.valueType === 'Dropdown'
                ? // eslint-disable-next-line eqeqeq
                  variation.values.find(val => val.id == variations[variationKey])
                : undefined,
            variationOpenValue:
              variation.valueType !== 'Dropdown' ? variations[variationKey] : undefined,
          })
        })
      sku.variations = variationsToUpdate
      sku.prices = prices

      commit('UPDATE_SKU', { sku, index })
    },

    removeSku({ commit }, index) {
      commit('REMOVE_SKU', index)
    },

    cleanProduct({ commit }) {
      commit('CLEAN_PRODUCT')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    deleteTieredPrice({ commit }, { priceTableId, storeId }) {
      commit('DELETE_TIERED_PRICES', { priceTableId, storeId })
    },

    setTieredPrice({ commit }, tieredPrices) {
      commit('SET_TIERED_PRICES', tieredPrices)
    },
  },
}
